import React from "react";

export const Footer = () => {
  return (
    <div>
      <footer className="container">
        <p className="float-end animate-bounce" style={{ cursor: "pointer" }}>
        </p>
        <p>
          &copy; 2022–{new Date().getFullYear()} Andrew's Cookin' &middot;{" "}
          <a href="/privacy-conditions">Privacy</a> &middot;{" "}
          <a href="/terms-and-conditions">Terms</a>
        </p>
      </footer>
    </div>
  );
};
