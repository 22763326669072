import React from "react";
import { useEffect, lazy } from "react";
//import "../styles/home.css";
import { useNavigate, Navigate } from "react-router-dom";
import i18n from "./../i18n";
import "../styles/heroes.css";
import "../styles/carousel.css";
// import "../styles/pricing.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import recipePageImage from "../resources/recipe-page-light-small.png";
import recipePageImageDark from "../resources/recipe-page-dark-small.png";
import recipeSearchImage from "../resources/recipe-search-window-small.png";
import recipeSearchImageDark from "../resources/recipe-search-window-dark-small.png";
import youTubeImage from "../resources/youtube-channel-promo-light-small.png";
import youTubeImageDark from "../resources/youtube-channel-promo-dark-small.png";
// import recipesImage from "../resources/recipes-picture.png";
import Pricing from "../components/pricing/Pricing";
import { Footer } from "../components/main/Footer";
import MetaTags from "../components/seo/MetaTags";
import { Helmet } from "react-helmet";
// import Carousel from "react-bootstrap/Carousel";
import CarouselItem from "../components/main/CarouselItem.js";
// import recipesImageDark from "../resources/recipes-picture-dark.png";

function Home(props) {
  // const Footer = lazy(() => import("../components/main/Footer.js"));
  useEffect(() => {
    //On scroll animation trigger
    const observerLeft = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const slide = entry.target.querySelector(".animate-slide-in-left");
        if (slide !== null) {
          // // repeat animations when scrolled past
          // if (entry.isIntersecting) {
          //   slide.classList.add("left-slide");
          //   return;
          // }
          // slide.classList.remove("left-slide");
          if (entry.isIntersecting) {
            slide.classList.add("left-slide");
          }
        }
      });
    });

    const observerRight = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const slide2 = entry.target.querySelector(".animate-slide-in-right");
        if (slide2 !== null) {
          // if (entry.isIntersecting) {
          //   slide2.classList.add("right-slide");
          //   return;
          // }
          // slide2.classList.remove("right-slide");
          if (entry.isIntersecting) {
            slide2.classList.add("right-slide");
          }
        }
      });
    });

    observerLeft.observe(document.querySelector(".animate-wrapper-left"));
    process.env.REACT_APP_PRODUCT_LAUNCH === "true" &&
      observerLeft.observe(document.querySelector(".animate-wrapper-left2"));
    process.env.REACT_APP_PRODUCT_LAUNCH === "false" &&
      observerRight.observe(document.querySelector(".animate-wrapper-right"));
  });

  // var myCarousel = document.querySelector("#myCarousel");
  // var carousel = new bootstrap.Carousel(myCarousel, {
  //   interval: 2000,
  //   wrap: false,
  // });

  const history = useNavigate();

  const loginPage = () => {
    history("/login");
  };
  // const searchPage = () => {
  //   history("/recipe-search");
  // };
  const youTubePage = () => {
    window.open("https://www.youtube.com/@AndrewsCookin");
  };

  return (
    <div role="main" className="mx-8 flex flex-col h-screen justify-between">
      {props.user && props.firstTime && <Navigate to={"/recipe-search"} />}
      <div>
        <div className="container my-5 fade-in-style">
          <div className="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg hover:scale-105 transform transition duration-300">
            <div className="col-lg-7 p-3 p-lg-5 pt-lg-3">
              <h1 className="display-4 fw-bold lh-1 text-start">
                {process.env.REACT_APP_PRODUCT_LAUNCH === "true"
                  ? i18n.t("landingPage_title_launch")
                  : i18n.t("landingPage_title")}
              </h1>
              <p className="lead text-start">
                {process.env.REACT_APP_PRODUCT_LAUNCH === "true"
                  ? i18n.t("landingPage_description_launch")
                  : i18n.t("landingPage_description")}

                <MetaTags
                  name="description"
                  content={i18n.t("landingPage_description")}
                ></MetaTags>
                <Helmet>
                  <link
                    rel="canonical"
                    href="https://www.andrewscookin.com"
                  ></link>
                </Helmet>
              </p>
              <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
                {process.env.REACT_APP_PRODUCT_LAUNCH === "true" ? (
                  <a
                    // onClick={searchPage}
                    type="button"
                    className="btn btn-primary btn-lg px-4 me-md-2 fw-bold"
                    href="/#demo"
                  >
                    {i18n.t("landingPage_try_button_description_launch")}
                  </a>
                ) : (
                  <a
                    // onClick={searchPage}
                    type="button"
                    className="btn btn-primary btn-lg px-4 me-md-2 fw-bold"
                    href="/recipes"
                  >
                    {i18n.t("landingPage_try_button_description")}
                  </a>
                )}
                {process.env.REACT_APP_PRODUCT_LAUNCH === "true" ? (
                  <button
                    onClick={loginPage}
                    type="button"
                    className="btn btn-outline-secondary btn-lg px-4"
                  >
                    {i18n.t("landingPage_log_in_button_description")}
                  </button>
                ) : (
                  <button
                    onClick={youTubePage}
                    type="button"
                    className="btn btn-outline-secondary btn-lg px-4"
                  >
                    {i18n.t("landingPage_youtube_button_description")}
                  </button>
                )}
              </div>
            </div>
            <div className="col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg rounded">
              {process.env.REACT_APP_PRODUCT_LAUNCH === "true" ? (
                <picture>
                  <source
                    srcSet={recipeSearchImageDark}
                    media="(prefers-color-scheme:dark)"
                  ></source>
                  <img
                    className="rounded-lg-3 remove-img-in-responsive"
                    src={recipeSearchImage}
                    alt=""
                    width="720"
                  ></img>
                </picture>
              ) : (
                <picture>
                  <source
                    srcSet={recipePageImageDark}
                    media="(prefers-color-scheme:dark)"
                  ></source>
                  <img
                    className="rounded-lg-3 remove-img-in-responsive"
                    src={recipePageImage}
                    alt="Recipe List Image"
                    width="720"
                  ></img>
                </picture>
              )}
            </div>
          </div>
        </div>
      </div>
      <hr className="featurette-divider"></hr>
      {/* <div id="myCarousel" className="carousel slide" data-bs-ride="carousel"> */}
      {/* Recipe Courusel Thingy */}
      <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></button>
        </div>
        <div class="carousel-inner">
          <CarouselItem
            active={true}
            title={"Perfect Chilli Recipe"}
            description={
              "A wonderful Chili recipe that is sure to warm you up on a cold day. And your mouth on any day."
            }
            link={
              "https://www.andrewscookin.com/recipes/how-to-make-perfect-chili"
            }
            image={"https://i.imgur.com/rqssuhU.jpeg"}
          />
          <CarouselItem
            active={false}
            title={'Berries & Cream from the Movie "Chef"'}
            description={
              'Have you seen "Chef"? The desert that Jon Faverou makes in his movie "Chef" is super simple, yet so many flavours! Refreshing, sweet, and creamy.'
            }
            link={
              "https://www.andrewscookin.com/recipes/jon-favreaus-berries-and-cream-from-chef"
            }
            image={"https://i.imgur.com/Se4WMNl.jpeg"}
          />
          <CarouselItem
            active={false}
            title={"Perfect BLT Recipe in Under 2 Minutes"}
            description={
              "This BLT recipe needs no introduction. The perfect BLT recipe in under 2 minutes is all you need to hear."
            }
            link={
              "https://www.andrewscookin.com/recipes/blt-sandwitch-recipe-in-under-2-minutes"
            }
            image={"https://i.imgur.com/NsVzUBz.jpeg"}
          />
          <CarouselItem
            active={false}
            title={"Florentine Cookies"}
            description={
              "A wonderful cookie recipe that is fit for any holiday or leasure day. This recipe is sure to impress with only a couple ingredients and no prep."
            }
            link={"https://www.andrewscookin.com/recipes/florentines-recipe"}
            image={"https://i.imgur.com/9wGbzUk.jpeg"}
          />
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <hr className="featurette-divider"></hr>

      {/* YouTuber thingy */}
      <div className="animate-wrapper-left">
        <div className="row featurette animate-slide-in-left">
          <div className="col-md-7">
            <h2 className="featurette-heading">
              From the Creator of{" "}
              <span className="text-muted"> YouTube videos.</span>
            </h2>
            <p className="lead">
              Andrew's Cookin' Official Recipe Website is Full of Time Tested
              Recipes everyone Will Enjoy! No Bullsh*t!
            </p>
          </div>
          <div className="col-md-5 crop">
            <picture>
              <source
                className="cursor-pointer"
                srcSet={youTubeImageDark}
                media="(prefers-color-scheme:dark)"
              ></source>
              <img
                onClick={youTubePage}
                className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto remove-img-in-responsive cursor-pointer"
                width="500px"
                height="500px"
                alt="Image of Andrew's Cookin' YouTube Channel"
                src={youTubeImage}
              ></img>
            </picture>
          </div>
        </div>
      </div>
      {process.env.REACT_APP_PRODUCT_LAUNCH === "false" && (
        <div>
          <hr className="featurette-divider"></hr>
          {/* Recipe Search Tool */}
          <div className="animate-wrapper-right">
            <div className="row featurette animate-slide-in-right">
              <div className="order-md-1">
                <h2 className="featurette-heading" style={{ marginTop: "0px" }}>
                  A Recipe Search Tool{" "}
                  <span className="text-muted">Powered by A.I.</span>
                </h2>
                <p className="lead">Comming Soon...</p>
                <small>
                  Joking, there will be no A.I. Only <b>REAL</b> Recipes
                </small>
              </div>
              <div className="col-md-5 order-md-1 crop border border-gray-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
                <div className="animate-pulse flex space-x-4">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-slate-700 rounded"></div>
                    <div className="space-y-3">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                        <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                      </div>
                      <div className="h-2 bg-slate-700 rounded"></div>
                    </div>
                  </div>

                  <div className="flex-1 space-y-6 py-1">
                    <div className="space-y-3">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                        <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                      </div>
                      <div className="h-2 bg-slate-700 rounded"></div>
                    </div>
                    <div className="h-2 bg-slate-700 rounded"></div>
                  </div>
                </div>
                <div className="animate-pulse flex space-x-4">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-slate-700 rounded"></div>
                    <div className="space-y-3">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                        <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                      </div>
                      <div className="h-2 bg-slate-700 rounded"></div>
                    </div>
                  </div>

                  <div className="flex-1 space-y-6 py-1">
                    <div className="space-y-3">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                        <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                      </div>
                      <div className="h-2 bg-slate-700 rounded"></div>
                    </div>
                    <div className="h-2 bg-slate-700 rounded"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {process.env.REACT_APP_PRODUCT_LAUNCH === "true" && (
        <div>
          <hr className="featurette-divider" id="demo"></hr>
          {/* Allrecipe */}
          <div className="animate-wrapper-right">
            <div className="row featurette  animate-slide-in-right">
              <div className="col-md-7 order-md-2">
                <h2 className="featurette-heading">
                  Oh yeah, it’s that good.{" "}
                  <span className="text-muted">See for yourself.</span>
                </h2>
                <p className="lead">
                  Infinate amount of recipes for you to Try and Enjoy!
                </p>
              </div>
              <div className="col-md-5 order-md-1 crop">
                {/* <img
                className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto remove-img-in-responsive"
                width="500px"
                height="500px"
                alt=""
                src={recipesImage}
              ></img> */}
                <iframe
                  className="mx-auto remove-img-in-responsive"
                  width="650"
                  height="366"
                  // src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1&controls=0&loop=1"
                ></iframe>
              </div>
            </div>
          </div>
          <hr className="featurette-divider"></hr>
          {/* kuky thingy */}
          <div className="animate-wrapper-left2">
            <div className="row featurette animate-wrapper-left animate-slide-in-left">
              <div className="col-md-7">
                <h2 className="featurette-heading">
                  Use intricate features.{" "}
                  <span className="text-muted">
                    Customize your recipe Search!
                  </span>
                </h2>
                <p className="lead">
                  Use Filters, Pantry features and so much More! Pick your Diet
                  and find a Random recipe you might enjoy. Fill in a
                  questionaire, pick a Cuisine, the possibilities are ENDLESS!
                </p>
              </div>
              <div className="col-md-5 crop">
                <picture>
                  <source
                    srcSet={recipeSearchImageDark}
                    media="(prefers-color-scheme:dark)"
                  ></source>
                  <img
                    className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto remove-img-in-responsive"
                    width="500"
                    height="500"
                    alt="Recipe Search Application Image"
                    src={recipeSearchImage}
                  ></img>
                </picture>
              </div>
            </div>
          </div>
        </div>
      )}

      {process.env.REACT_APP_PRODUCT_LAUNCH === "true" && (
        <div>
          <hr className="featurette-divider" id="pricing"></hr>
          <Pricing email={props.user}></Pricing>
        </div>
      )}
      <hr className="featurette-divider"></hr>
      {/* </div> */}
      <Footer />
    </div>
  );
}
export default Home;
