import React from "react";

const CarouselItem = ({ active, image, title, description, link }) => {
  return (
    <div className={active ? "carousel-item active" : "carousel-item"}>
      <img src={image} style={{ opacity: "50%" }} alt={title}></img>

      <div className="container">
        <div className="carousel-caption text-start">
          <h1>{title}</h1>
          <p>{description}</p>
          <p>
            <a className="btn btn-lg btn-primary" href={link}>
              Read Recipe
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CarouselItem;
